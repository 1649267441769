import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import './itemNavElement.scss'


const ItemNavElement = props => {
  const isCurrentTab = props.currentTabId === props.element.get('parent-element-id')

  const setCurrentTab = event => {
    if (event)
      event.preventDefault()

    props.setCurrentTab(props.element.get('parent-element-id'))
  }

  return (
    <li className={classnames('item-nav-element', {active: isCurrentTab})} onClick={setCurrentTab} onTouchEnd={setCurrentTab}>
      {props.element.get('display-name')}
    </li>
  )
}

ItemNavElement.propTypes = {
  currentTabId: PropTypes.string,
  element: PropTypes.instanceOf(Map).isRequired,
  setCurrentTab: PropTypes.func
}

export default ItemNavElement
