import {useState} from 'react'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import Button from '../../../../../shared_components/Button'
import Modal from '../../../../../shared_components/Modal'
import DisableWarning from './DisableWarning'

import checkMarkImage from '../../../../assets/images/green-circle-check.svg'


const TfaStatus = props => {
  const [showModal, setShowModal] = useState(false)
  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)
  const showDisableButton = (
    !props.organizationConfig.get('mandatory-tfa-enabled') &&
    !(props.isFuneralHome && props.isFirmUser) &&
    !props.isQuarterback
  )

  return (
    <div className='tfa-status'>
      {props.status === 'enabled' ?
        (
          <div className='status-body'>
            <div className='status-text'>
              <img className='tfa-enabled-icon' src={checkMarkImage} />
              <p>Two-Step Verification is enabled for your account.</p>
              {props.lastEnabledAt && (
                <p>You enabled it on {props.lastEnabledAt}</p>
              )}
            </div>
            {showDisableButton && (
              <Button className='control-btn' onClick={openModal}>
              Disable
              </Button>
            )}
            <Modal visible={showModal} closer={closeModal}>
              <div className='auth-content'>
                <DisableWarning {...props} closer={closeModal} />
              </div>
            </Modal>
          </div>
        ) :
        (
          <span>Disabled</span>
        )}
    </div>
  )
}

TfaStatus.propTypes = {
  lastEnabledAt: PropTypes.string,
  status: PropTypes.string,
  organizationConfig: PropTypes.instanceOf(Map),
  isFuneralHome: PropTypes.bool,
  isFirmUser: PropTypes.bool,
  isQuarterback: PropTypes.bool
}

export default TfaStatus
