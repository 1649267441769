import PropTypes from 'prop-types'
import React from 'react'
import {Map} from 'immutable'

import Button from '../../../../shared_components/Button'
import {ButtonGroupWrapper} from '../../../../shared_components/blocks/Blocks'
import {Cancel, Enable} from '../../../../shared_components/ButtonTypes'
import Modal from '../../../../shared_components/Modal'

import SessionStore from '../../stores/SessionStore'
import TfaConfigStore from '../../stores/TfaConfigStore'
import TfaWizard from './tfa_setup/TfaWizard'
import TfaWizardStore from '../../stores/TfaWizardStore'
import WizardActions from '../../actions/WizardActions'

import phoneWithTwoFactorAuthenticationCode from '../../../assets/images/phone-with-two-factor-authentication-code.png'

import './tfaSetup.scss'

export default class TfaSetup extends React.Component {
  constructor() {
    super()

    this.state = {
      showModal: false,
      storesInitialized: TfaConfigStore.initialized() && SessionStore.initialized()
    }
    this.onUpdate = this.onUpdate.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }
  componentDidMount() {
    SessionStore.addChangeListener(this.onUpdate)
    TfaWizardStore.addChangeListener(this.onUpdate)
    TfaConfigStore.addChangeListener(this.onUpdate)
  }
  onUpdate() {
    if (TfaWizardStore.getTopLevelView() === 'DONE')
      this.props.exitTfa()
    else
      this.setState({storesInitialized: TfaConfigStore.initialized() && SessionStore.initialized()})
  }
  componentWillUnmount() {
    SessionStore.removeChangeListener(this.onUpdate)
    TfaWizardStore.removeChangeListener(this.onUpdate)
    TfaConfigStore.removeChangeListener(this.onUpdate)
  }
  showModal() { this.setState({showModal: true}) }
  showBackButton() { return !(this.props.onboarding || (this.props.firmUser && this.props.organizationConfig.get('mandatory-tfa-enabled'))) }
  hideModal(event) {
    if (event) event.preventDefault()

    if (this.props.wizardActive)
      WizardActions.confirmCancelSetup()
    else
      this.setState({showModal: false})
  }

  closer() {
    if (!(this.props.onboarding && this.props.organizationConfig.get('mandatory-tfa-enabled')))
      return this.hideModal
  }

  render() {
    return (
      <div className='tfa-setup'>
        <h1>Enable Two-Step Verification to keep your account more secure.</h1>
        <div className='tfa-intro-container row-wrapper'>
          <img
            alt='Phone with two factor authentication code'
            className='code-img'
            src={phoneWithTwoFactorAuthenticationCode}
          />
          <div className='column-wrapper tfa-text-wrapper'>
            <p className='tfa-intro-text'>
              Whenever you sign into your Everplan from a new computer, device, or browser, we will send
              a unique code to your phone that you must input as part of your login.
            </p>
            <p className='tfa-intro-text'>
              This <strong>extra layer of security</strong> makes sure that even if a bad guy steals your
              password from you (or from a site that's less secure than Everplans), he won't be able to
              access your Everplan.
            </p>
          </div>
        </div>
        <ButtonGroupWrapper>
          {this.showBackButton() && <Button className='back-btn' onClick={this.props.exitTfa}>&laquo; Back</Button>}
          <Enable disabled={!this.state.storesInitialized} onClick={this.showModal}>
            <span className='enable-btn-text'>Enable two-step verification</span>
          </Enable>
          {
            (
              this.props.onboarding &&
              !this.props.organizationConfig.get('mandatory-tfa-enabled') &&
              !this.props.isQuarterback &&
              !(this.props.firmUser && this.props.isFuneralHome)
            ) &&
            <Cancel className='skip-enabling-tfa' onClick={this.props.exitTfa}>No, thanks</Cancel>
          }
        </ButtonGroupWrapper>
        <Modal visible={this.state.showModal} closer={this.closer()}>
          <div className='auth-content' onClick={WizardActions.clearTfaErrors}>
            <TfaWizard
              closer={this.hideModal}
              exitTfa={this.props.exitTfa}
              tfaConfig={this.props.tfaConfig}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

TfaSetup.propTypes = {
  exitTfa: PropTypes.func,
  firmUser: PropTypes.bool,
  onboarding: PropTypes.bool,
  organizationConfig: PropTypes.instanceOf(Map),
  isQuarterback: PropTypes.bool,
  tfaConfig: PropTypes.object,
  wizardActive: PropTypes.bool,
  isFuneralHome: PropTypes.bool
}

TfaSetup.defaultProps = {
  onboarding: false,
  wizardActive: false
}
