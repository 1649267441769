import {useCallback} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import TfaStatus from './TfaStatus'
import TfaPrimaryPhone from './TfaPrimaryPhone'
import TfaSecondaryPhone from './TfaSecondaryPhone'
import TfaBackup from './TfaBackup'
import Loader from '../../../../../shared_components/NewLoader'


const TfaInfoDisplay = props => {
  const {tfaConfig, organizationConfig, loading, isFuneralHome, isFirmUser, isQuarterback} = props

  const tfaConfigAttribute = useCallback(
    attribute =>
      // Converting to JS and doing this check because this component and other ones inside are
      // expecting a plain JS object when present and null when set to null in the store.
      // Doing tfaConfig.get(attribute, Map()).toJS() fails.
      (tfaConfig.get(attribute) ? tfaConfig.get(attribute).toJS() : null),
    [tfaConfig]
  )

  return (
    <Loader loading={loading}>
      <div className='tfa-info'>
        <h1>Two-Step Verification</h1>
        <div>
          <h3>Status</h3>
          <TfaStatus
            status={tfaConfig.get('status')}
            lastEnabledAt={tfaConfig.get('last_enabled_at')}
            organizationConfig={organizationConfig}
            isFuneralHome={isFuneralHome}
            isFirmUser={isFirmUser}
            isQuarterback={isQuarterback}
          />
          <h3>Primary Phone Number</h3>
          {tfaConfig.get('primary_number') && (
            <TfaPrimaryPhone
              primaryNumber={tfaConfigAttribute('primary_number')}
            />
          )}
          <h3>Secondary Phone Number</h3>
          <TfaSecondaryPhone
            secondaryNumber={tfaConfigAttribute('secondary_number')}
          />
          <h3>Backup Code</h3>
          <TfaBackup />
        </div>
      </div>
    </Loader>
  )
}

TfaInfoDisplay.propTypes = {
  tfaConfig: PropTypes.instanceOf(Map),
  organizationConfig: PropTypes.instanceOf(Map),
  loading: PropTypes.bool,
  isFuneralHome: PropTypes.bool,
  isFirmUser: PropTypes.bool,
  isQuarterback: PropTypes.bool
}

export default TfaInfoDisplay
