import classnames from 'classnames'

/** @module ButtonTypes
 *
 * @deprecated Do not use any of these in new code. Use the `button`s in
 *   +dashboard/src/components/blocks/Buttons.react.js+ instead.
 * @desc
 * Legacy `button` components.
 */

import PropTypes from 'prop-types'

import React from 'react'

import Button from './Button'

import {noop} from '../lib/tools'
import routerUtils from '../lib/routerUtils'

export const BasicButton = props => {
  const buttonProps = {
    className: props.className ? props.className : '',
    disabled: props.disabled ? props.disabled : false,
    loading: props.loading ? props.loading : false,
    onClick: props.onClick ? props.onClick : noop
  }

  return (
    <Button {...buttonProps}>
      {props.children ? props.children : 'MISSING BUTTON CHILDREN'}
    </Button>
  )
}

BasicButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export const Accept = props => BasicButton({children: props.children ? props.children : 'Accept',
  className: `btn-accept ${props.className ? props.className : ''}`,
  onClick: props.onClick,
  loading: props.loading
})

Accept.propTypes = {
  onClick: PropTypes.func
}

export const Cancel = props => BasicButton({children: props.children ? props.children : 'Cancel',
  className: `btn-cancel ${props.className ? props.className : ''}`,
  disabled: props.disabled,
  onClick: props.onClick,
  loading: props.loading
})

Cancel.propTypes = {
  onClick: PropTypes.func
}

export const Continue = props => BasicButton({children: props.children ? props.children : 'Continue',
  className: `btn-primary ${props.className ? props.className : ''}`,
  disabled: props.disabled,
  onClick: props.onClick,
  loading: props.loading
})

Continue.propTypes = {
  onClick: PropTypes.func
}

export const ControlButton = props => {
  const buttonText = props.buttonText ? props.buttonText : 'MISSING BUTTON TEXT'
  const onClick = props.onClick ? props.onClick : noop

  return (
    <button className={classnames('control-btn-v2', props.className)} onClick={onClick} disabled={props.disabled}>
      {props.faClass && <i className={classnames('fa', props.faClass)} />}
      {buttonText}
    </button>
  )
}

ControlButton.propTypes = {
  buttonText: PropTypes.string,
  faClass: PropTypes.string,
  onClick: PropTypes.func
}

export const Back = props => BasicButton({children: props.children ? props.children : '« Back',
  className: `btn-cancel ${props.className ? props.className : ''}`,
  onClick: routerUtils.goBack,
  loading: props.loading
})

Back.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func
}

export const Enable = props => BasicButton({
  children: props.children ? props.children : 'Enable',
  className: classnames('enable-btn', {[props.className]: props.className}),
  disabled: props.disabled,
  onClick: props.onClick,
  loading: props.loading
})

Enable.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}
